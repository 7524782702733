import { convertKeysToCamelCase } from 'core/utils';
import { getTimeFromDate } from 'utils/date';
import { getGlobalContext } from 'core/globals';
import { SimpleLegalEntityModel, LegalEntityAvatarModel } from 'contacts/models/legalEntity';
import { SimpleDocumentModel } from 'media/models/document';
import { EventGroupStatus, EventGroupType, EventStatus } from 'governanceEvents/types';
import { Model } from 'core/model';
import {
  GovernanceEventDetailModelProps,
  GovernanceEventInviteeModelProps,
  GovernanceEventListItemModelProps,
  IGovernanceEventDetailModel,
  IGovernanceEventInviteeModel,
  IGovernanceEventListItemModel,
} from 'governanceEvents/interfaces';
import { getGroupByStatus } from '../utils';

const {
  activeEntity: { id: activeEntityId },
  custodian: { active: custodianActive },
} = getGlobalContext();

export class GovernanceEventListItemModel extends Model implements IGovernanceEventListItemModel {
  subject: string;
  date: Date;
  owner: SimpleLegalEntityModel;
  groupStatus: EventGroupStatus;
  eventType: EventGroupType;
  id: number;
  status: EventStatus;

  constructor({
    dateTime,
    owner,
    type,
    group,
    status,
    ...restProps
  }: GovernanceEventListItemModelProps) {
    super(restProps);
    this.eventType = `${group}_${type}` as EventGroupType;
    this.date = new Date(dateTime);
    this.owner = new SimpleLegalEntityModel(owner);
    this.status = status;
    this.groupStatus = getGroupByStatus(status) as EventGroupStatus;
  }
}

export class GovernanceEventInviteeModel extends Model implements IGovernanceEventInviteeModel {
  voteWeight: number;
  avatar: LegalEntityAvatarModel;
  id: number;
  name: string;
  indicatedParticipation: boolean;
  checkedIn: boolean;
  votedByProxy: boolean;
  roles: string[];
  castingVote: boolean;

  constructor({
    avatar,
    ...restProps
  }: GovernanceEventInviteeModelProps) {
    super(restProps);
    this.avatar = new LegalEntityAvatarModel(convertKeysToCamelCase(avatar));
  }
}

export class GovernanceEventDetailModel extends Model implements IGovernanceEventDetailModel {
  documents: SimpleDocumentModel[];
  _dateTime: Date;
  _time: string;
  _proxy: SimpleLegalEntityModel | Record<string, never>;
  _chairman: SimpleLegalEntityModel | Record<string, never>;
  _secretary: SimpleLegalEntityModel | Record<string, never>;
  _shareClasses: Record<string, string | number>[];
  _invitees: IGovernanceEventInviteeModel[];
  introOutro: {
    invitation: { introText: string; outroText: string; };
    minutes: { introText: string; outroText: string; };
  };
  timeZone: string;
  statuses: EventStatus[];
  location: string;
  locationCustom: string;
  locationType: string;
  languages: string[];
  videoUrl: string;
  noOfInvitees: number;
  noOfShareClasses: { included: number; total: number; };
  proxyVotingEnabled: boolean;
  checkedIn: boolean;
  indicatedParticipation: boolean;
  introTextSeen: boolean;
  voterProxyStatus: string;
  proxyVotesReleased: boolean;
  isVotingParticipant: boolean;
  invitationIntro: string;
  invitationOutro: string;
  minutesIntro: string;
  minutesOutro: string;
  hasCustodialInvitees: boolean;
  minutesSignatories?: { required_amount: number; signed_ids: number[]; } | undefined;
  date: Date;
  owner: SimpleLegalEntityModel;
  groupStatus: EventGroupStatus;
  eventType: EventGroupType;
  id: number;
  subject: string;
  status: EventStatus;

  constructor({
    owner, group, type, dateTime, timeZone, proxy, chairman, secretary, documents, shareClasses = [],
    invitees = [], minutesSignatories, invitationIntro, invitationOutro,
    minutesIntro, minutesOutro, ...restProps
  }: GovernanceEventDetailModelProps) {
    super(restProps);
    this.eventType = `${group}_${type}`;
    this.timeZone = timeZone;
    this._dateTime = new Date(dateTime);
    this._time = getTimeFromDate(new Date(dateTime), timeZone);
    this.owner = new SimpleLegalEntityModel(owner);
    this._proxy = proxy ? new SimpleLegalEntityModel(convertKeysToCamelCase(proxy)) : {};
    this._chairman = chairman ? new SimpleLegalEntityModel(convertKeysToCamelCase(chairman)) : {};
    this._secretary = secretary ? new SimpleLegalEntityModel(convertKeysToCamelCase(secretary)) : {};
    this.documents = SimpleDocumentModel.fromArrayResponse(documents);
    this._shareClasses = shareClasses.map((item) => convertKeysToCamelCase(item));
    this._invitees = invitees.map((invitee) => (new GovernanceEventInviteeModel(
      convertKeysToCamelCase(invitee),
    )));
    this.minutesSignatories = minutesSignatories ? convertKeysToCamelCase(minutesSignatories) : undefined;
    this.introOutro = {
      invitation: {
        introText: invitationIntro,
        outroText: invitationOutro,
      },
      minutes: {
        introText: minutesIntro,
        outroText: minutesOutro,
      },
    };
  }

  // Datetime and time setters and getters
  get dateTime() { return this._dateTime; }

  set dateTime(dateTime) { this._dateTime = new Date(dateTime); }

  get time() { return this._time; }

  set time(param) {
    const { dateTime, timeZone } = param as unknown as { dateTime: string; timeZone: string; };
    this._time = getTimeFromDate(new Date(dateTime), timeZone);
  }

  // Roles setters and getters
  get proxy() { return this._proxy; }

  set proxy(proxy) {
    this._proxy = SimpleLegalEntityModel.fromResponse(convertKeysToCamelCase(proxy)) || {};
  }

  get chairman() { return this._chairman; }

  set chairman(chairman) {
    this._chairman = SimpleLegalEntityModel.fromResponse(convertKeysToCamelCase(chairman)) || {};
  }

  get secretary() { return this._secretary; }

  set secretary(secretary) {
    this._secretary = SimpleLegalEntityModel.fromResponse(convertKeysToCamelCase(secretary)) || {};
  }

  get shareClasses() { return this._shareClasses; }

  set shareClasses(shareClasses) {
    this._shareClasses = shareClasses.map((item) => convertKeysToCamelCase(item));
  }

  get invitees() { return this._invitees; }

  set invitees(invitees) {
    this._invitees = GovernanceEventInviteeModel.fromResponse(invitees) || {};
  }

  get isOwner() {
    return activeEntityId === this.owner.id;
  }

  get isEditable() {
    return this.isOwner && this.status === 'in_preparation';
  }

  get user() {
    return {
      id: activeEntityId,
      isCustodial: custodianActive,
      isOwner: this.isOwner,
      indicatedParticipation: this.indicatedParticipation,
      checkedIn: this.checkedIn,
      introTextSeen: this.introTextSeen,
      isSecretary: activeEntityId === this.secretary.id,
      isChairman: activeEntityId === this.chairman.id,
      isProxy: activeEntityId === this.proxy.id,
      isVotingUser: this.isVotingParticipant,
      proxyVotingStatus: this.voterProxyStatus,
    };
  }

  get eventDetails() {
    return {
      ...this,
      includedShareClasses: `${this.noOfShareClasses.included}/${this.noOfShareClasses.total}`,
      companyAvatar: this.owner.avatar,
      isEditable: this.isEditable,
      dateTime: this.dateTime,
    };
  }
}
