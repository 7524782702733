import { icons } from 'core/icons';
import { MenuItemsIds } from '../constants';
import { MenuItemProps } from '../types';
import {
  account,
  artworksService,
  assets,
  associationsMembership,
  dashboard,
  dataRooms,
  dataRoomsVerifierService,
  documents,
  events,
  inbox,
  markets,
} from './shared';

export const personalMenuItems: MenuItemProps[] = [
  dashboard,
  inbox,
  events,
  assets,
  dataRooms,
  {
    id: MenuItemsIds.SERVICES,
    label: gettext('Services'),
    url: '/services/',
    icon: icons.transfer,
    activeIcon: icons.transferActive,
    subitems: [
      {
        id: MenuItemsIds.SERVICES_ARBITER,
        label: gettext('Arbiter'),
        url: '/arbiter/',
      },
      artworksService,
      {
        id: MenuItemsIds.SERVICES_CERTIFICATES_ISSUER,
        label: gettext('Certificates'),
        url: '/certificates/issue/',
      },
      dataRoomsVerifierService,
    ],
  },
  {
    id: MenuItemsIds.MEMBERSHIPS,
    label: gettext('Memberships'),
    url: '/memberships/',
    icon: icons.boardroom,
    activeIcon: icons.boardroomActive,
    subitems: [
      {
        id: MenuItemsIds.MEMBERSHIPS_BOARDROOM,
        label: gettext('Boardrooms'),
        url: '/memberships/boardroom/',
      },
      associationsMembership,
    ],
  },
  documents,
  markets,
  account,
];
